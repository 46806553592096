///
/// Stellar by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Nav */

	#nav {
		@include vendor('transition', (
			'background-color #{_duration(transition)} ease',
			'border-top-left-radius #{_duration(transition)} ease',
			'border-top-right-radius #{_duration(transition)} ease',
			'padding #{_duration(transition)} ease',
		));
		@include color-typography(invert);
		position: absolute;
		width: _size(inner);
		max-width: calc(100% - #{_size(element-margin) * 2});
		padding: 1em;
		background-color: _palette(invert, bg-alt);
		border-top-left-radius: _size(border-radius-main);
		border-top-right-radius: _size(border-radius-main);
		cursor: default;
		text-align: center;

		& + #main {
			padding-top: 4.25em;
		}

		ul {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				@include vendor('transition', (
					'margin #{_duration(transition)} ease'
				));
				display: inline-block;
				margin: 0 0.35em;
				padding: 0;
				vertical-align: middle;

				a {
					@include vendor('transition', (
						'font-size #{_duration(transition)} ease'
					));
					display: inline-block;
					height: 2.25em;
					line-height: 2.25em;
					padding: 0 1.25em;
					border: 0;
					border-radius: _size(border-radius);
					box-shadow: inset 0 0 0 1px transparent;

					&:hover {
						background-color: _palette(invert, border-bg);
					}

					&.active {
						background-color: _palette(invert, bg);
						box-shadow: none;
					}
				}
			}
		}

		&.alt {
			position: fixed;
			top: 0;
			padding: 0.5em 1em;
			background-color: transparentize(_palette(invert, bg-alt), 0.05);
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			z-index: _misc(z-index-base);

			ul {
				li {
					margin: 0 0.175em;

					a {
						font-size: 0.9em;
					}
				}
			}
		}

		@include breakpoint('<=small') {
			display: none;

			& + #main {
				padding-top: 0;
			}
		}
	}