///
/// Stellar by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Main */

	#main {
		@include color(invert);
		border-radius: _size(border-radius-main);

		> .main {
			@include padding(5em, 5em);
			border-top: solid 1px _palette(invert, border);

			&:first-child {
				border-top: 0;
			}

			> .image.main:first-child {
				margin: -5em 0 5em -5em;
				width: calc(100% + 10em);
				border-top-right-radius: _size(border-radius-main);
				border-top-left-radius: _size(border-radius-main);
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;

				img {
					border-top-right-radius: _size(border-radius-main);
					border-top-left-radius: _size(border-radius-main);
					border-bottom-right-radius: 0;
					border-bottom-left-radius: 0;
				}
			}
		}

		@include breakpoint('<=large') {
			> .main {
				@include padding(4em, 4em);

				> .image.main:first-child {
					margin: -4em 0 4em -4em;
					width: calc(100% + 8em);
				}
			}
		}

		@include breakpoint('<=medium') {
			> .main {
				@include padding(4em, 3em);

				> .image.main:first-child {
					margin: -4em 0 4em -3em;
					width: calc(100% + 6em);
				}
			}
		}

		@include breakpoint('<=small') {
			> .main {
				@include padding(3em, 2em);

				> .image.main:first-child {
					margin: -3em 0 2em -2em;
					width: calc(100% + 4em);
				}
			}
		}

		@include breakpoint('<=xsmall') {
			> .main {
				@include padding(3em, 1.5em);

				> .image.main:first-child {
					margin: -3em 0 1.5em -1.5em;
					width: calc(100% + 3em);
				}
			}
		}

		@include breakpoint('<=xxsmall') {
			border-radius: 0;

			> .main {
				@include padding(2.5em, 1em);

				> .image.main:first-child {
					margin: -2.5em 0 1.5em -1em;
					width: calc(100% + 2em);
					border-radius: 0;

					img {
						border-radius: 0;
					}
				}
			}
		}
	}