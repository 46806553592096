@import 'form';

#header {
	height: 100vh;
	background-image: url('/images/girl-hero-image.jpg');
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
	margin-bottom: 50px;
}

#header .content {
	position: absolute;
	margin: auto;
	left: 0;
	right: 0;
	bottom: 5%;
	max-width: 700px;
}

a.app_store_button {
	border-bottom: 0;
}

a.app_store_button img {
	width: 70%;
	max-width: 225px;
}

#header.alt .logo img {
	width: 30%;
	max-width: 120px;
	border: 2px solid #ccc;
	border-radius: 25%;
}

.panel-cover--overlay {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(68, 68, 68, 0.3);
	background-image: -webkit-linear-gradient(-410deg, rgba(68, 68, 68, 0.6) 20%, rgba(0, 0, 0, 0.9));
	background-image: linear-gradient(140deg,rgba(68, 68, 68, 0.0) 20%, rgba(0, 0, 0, 0.3));
}

.spotlight .image {
	border: 0;
	flex: 1;
}

.spotlight .image img {
	border-radius: 0;
	width: 100%;
	height: 100%;
}

#main {
	border-radius: 1em;
}

dl.alt dt {
	width: 5em;
	margin-right: 1em;
}

#header .content p {
	font-size: 1em;
	color: white;
}

@media screen and (max-width: 320px) {
	#header.alt h1  {
		font-size: 2em;
	}
}

.green {
	color: #3FCE62;
}

.red {
	color: #D74842;
}

.bold {
	font-weight: bold;;
}

.statistics li {
	border-radius: 8px;
	margin: 5px;
}
