///
/// Stellar by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Type */

	body {
		background-color: _palette(bg);
		color: _palette(fg);
	}

	body, input, select, textarea {
		font-family: _font(family);
		font-size: 17pt;
		font-weight: _font(weight);
		line-height: 1.65;

		@include breakpoint('<=xlarge') {
			font-size: 14pt;
		}

		@include breakpoint('<=large') {
			font-size: 12pt;
		}

		@include breakpoint('<=xxsmall') {
			font-size: 11pt;
		}
	}

	a {
		@include vendor('transition', (
			'color #{_duration(transition)} ease',
			'border-bottom #{_duration(transition)} ease'
		));
		text-decoration: none;
		border-bottom: dotted 1px;
		color: inherit;

		&:hover {
			border-bottom-color: transparent;
		}
	}

	strong, b {
		font-weight: _font(weight-bold);
	}

	em, i {
		font-style: italic;
	}

	p {
		margin: 0 0 _size(element-margin) 0;

		&.content {
			-moz-columns: 20em 2;
			-webkit-columns: 20em 2;
			-ms-columns: 20em 2;
			columns: 20em 2;
			-moz-column-gap: _size(element-margin);
			-webkit-column-gap: _size(element-margin);
			-ms-column-gap: _size(element-margin);
			column-gap: _size(element-margin);
			text-align: justify;
		}
	}

	h1, h2, h3, h4, h5, h6 {
		font-weight: _font(weight);
		line-height: 1.5;
		margin: 0 0 (_size(element-margin) * 0.35) 0;
		letter-spacing: _font(letter-spacing);

		a {
			color: inherit;
			text-decoration: none;
		}
	}

	h1 {
		font-size: 2.5em;
		line-height: 1.2;
	}

	h2 {
		font-size: 1.5em;
	}

	h3 {
		font-size: 1.25em;
	}

	h4 {
		font-size: 1.1em;
	}

	h5 {
		font-size: 0.9em;
	}

	h6 {
		font-size: 0.7em;
	}

	@include breakpoint('<=small') {
		h1 {
			font-size: 2em;
		}
	}

	sub {
		font-size: 0.8em;
		position: relative;
		top: 0.5em;
	}

	sup {
		font-size: 0.8em;
		position: relative;
		top: -0.5em;
	}

	blockquote {
		border-left: solid 4px;
		font-style: italic;
		margin: 0 0 _size(element-margin) 0;
		padding: (_size(element-margin) / 4) 0 (_size(element-margin) / 4) _size(element-margin);
	}

	code {
		border-radius: _size(border-radius);
		border: solid 1px;
		font-family: _font(family-fixed);
		font-size: 0.9em;
		margin: 0 0.25em;
		padding: 0.25em 0.65em;
	}

	pre {
		-webkit-overflow-scrolling: touch;
		font-family: _font(family-fixed);
		font-size: 0.9em;
		margin: 0 0 _size(element-margin) 0;

		code {
			display: block;
			line-height: 1.75;
			padding: 1em 1.5em;
			overflow-x: auto;
		}
	}

	hr {
		border: 0;
		border-bottom: solid 1px;
		margin: _size(element-margin) 0;

		&.major {
			margin: (_size(element-margin) * 1.5) 0;
		}
	}

	.align-left {
		text-align: left;
	}

	.align-center {
		text-align: center;
	}

	.align-right {
		text-align: right;
	}

	@mixin color-typography($p: null) {
		@if $p != null {
			background-color: _palette($p, bg);
			color: _palette($p, fg);
		}

		input, select, textarea {
			color: _palette($p, fg-bold);
		}

		a {
			&:hover {
				color: _palette($p, fg-bold);
			}
		}

		strong, b {
			color: _palette($p, fg-bold);
		}

		h1, h2, h3, h4, h5, h6 {
			color: _palette($p, fg-bold);
		}

		blockquote {
			border-left-color: _palette($p, border);
		}

		code {
			background: _palette($p, border-bg);
			border-color: _palette($p, border);
		}

		hr {
			border-bottom-color: _palette($p, border);
		}
	}

	@include color-typography;